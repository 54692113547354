import { Container, Typography, Button, Box } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Player } from "@lottiefiles/react-lottie-player";
import emailConfirmed from "../components/images/email_confirmation.json";
import Copyright from "../components/Copyright";

const ErrorPage = () => {
  const navigate = useNavigate();

  return (
    <Container maxWidth="sm" style={{ textAlign: "center", marginTop: "10vh" }}>
      <Player
        autoplay
        loop
        src={emailConfirmed}
        style={{ height: "300px", width: "300px", margin: "0 auto" }}
      />
      <Typography variant="h4" color="primary" gutterBottom>
        Email is verified!
      </Typography>
      <Typography variant="body1" gutterBottom>
        Your email has been successfully verified. You can now go back to the login page to access the platform.
      </Typography>
      <Box mt={4}>
        <Button
          variant="contained"
          color="primary"
          onClick={() => navigate("/login")}
        >
          Go to the login
        </Button>
      </Box>
      <Box mt={5}>
        <Copyright />
      </Box>
    </Container>
  );
};

export default ErrorPage;

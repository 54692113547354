// src/components/ProtectedPage.js
import React from "react";
import useSubscriptionVerification from "../hooks/useSubscriptionVerification";

const ProtectedPage = ({ children }) => {
    const isLoading = useSubscriptionVerification();

    if (isLoading) {
        return <p>Loading...</p>; // Add a spinner or placeholder as needed
    }

    return <>{children}</>;
};

export default ProtectedPage;

import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import SubscriptionContext from "../context/SubscriptionContext";
import { AuthContext } from "../context/AuthContext";

const baseUrl = process.env.REACT_APP_API_BASE_URL;

const useSubscriptionVerification = () => {
  const { isLoading: subscriptionContextLoading, setSubscriptionStatus } = useContext(SubscriptionContext);
  const { token } = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState(true); // Local loading state
  const navigate = useNavigate();

  useEffect(() => {
    const checkSubscriptionStatus = async () => {
      try {
        // Step 1: Fetch subscription details
        const subscriptionResponse = await fetch(`${baseUrl}/api/billings/subscription-details/`, {
          method: "GET",
          headers: {
            Authorization: `Token ${token}`,
            "Content-Type": "application/json",
          },
          credentials: "include",
        });
        if (!subscriptionResponse.ok) {
          throw new Error(`Failed to fetch subscription details. Status: ${subscriptionResponse.status}`);
        }
        const subscriptionData = await subscriptionResponse.json();
        const subscriptionStatus = subscriptionData?.subscriptions?.status;

        if (subscriptionStatus === "active") {
          console.log("Subscription is active. No redirect needed.");
          setSubscriptionStatus("active"); // Update SubscriptionContext if applicable
          setIsLoading(false);
          return;
        }

        // Step 2: If subscription is not active, create checkout session
        const checkoutResponse = await fetch(`${baseUrl}/api/billings/create-checkout-session/`, {
          method: "POST",
          headers: {
            Authorization: `Token ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            base_url: window.location.origin + "/",
            product_id: 1, // Adjust product_id as per your requirement
          }),
          credentials: "include",
        });

        if (!checkoutResponse.ok) {
          throw new Error(`Failed to create checkout session. Status: ${checkoutResponse.status}`);
        }

        const checkoutData = await checkoutResponse.json();
        const { checkoutUrl } = checkoutData;

        if (checkoutUrl) {
          console.log("Redirecting to checkout:", checkoutUrl);
          window.location.href = checkoutUrl; // Redirect to Stripe checkout
        } else {
          console.error("Checkout URL not found in response");
        }
      } catch (error) {
        console.error("Error verifying subscription or redirecting to checkout:", error);
      } finally {
        setIsLoading(false);
      }
    };

    if (!subscriptionContextLoading) {
      checkSubscriptionStatus();
    }
  }, [subscriptionContextLoading, token, navigate, setSubscriptionStatus]);

  return isLoading;
};

export default useSubscriptionVerification;

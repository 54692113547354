import React from "react";
import { useNavigate } from "react-router-dom";
import { Box, Button, Typography, Container } from "@mui/material";
import { Player } from "@lottiefiles/react-lottie-player";
import InsightLogo from "../components/images/insight_logo_color.svg";
import SuccessAnimation from "../components/images/success_animation.json";
import Confetti from "../components/images/confetti.json";
import Copyright from "../components/Copyright";

function Success() {
  const navigate = useNavigate(); // Initialize useNavigate for redirection

  return (
    <Container component="main" maxWidth="xs">
      {/* Full-page confetti animation */}
      <Box
        sx={{
          position: "fixed",
          top: 0,
          left: 0,
          width: "100vw",
          height: "100vh",
          zIndex: -1, // Send the confetti to the background
          pointerEvents: "none", // Make it click-through
        }}
      >
        <Player
          autoplay
          loop
          src={Confetti}
          style={{ height: "100%", width: "100%" }}
        />
      </Box>

      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          textAlign: "center",
        }}
      >
        <img src={InsightLogo} alt="Insight Logo" style={{ height: "100px" }} />
        <Player
          autoplay
          loop
          src={SuccessAnimation}
          style={{ height: "300px", width: "300px", margin: "0 auto" }}
        />

        <Typography variant="h5" component="h1" gutterBottom>
          Welcome on board!
        </Typography>
        <Typography variant="body1" color="textSecondary" sx={{ mb: 3 }}>
          Thank you for joining Insight.
        </Typography>
        <Button
          variant="contained"
          color="primary"
          fullWidth
          sx={{ mt: 2 }}
          onClick={() => navigate("/dashboard")}
        >
          Go to Dashboard
        </Button>
      </Box>
      <Box mt={5}>
        <Copyright />
      </Box>
    </Container>
  );
}

export default Success;

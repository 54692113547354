// utils/categoryUtils.js

/**
 * Sorts an array of category names alphabetically.
 * @param {string[]} categories - Array of category names.
 * @returns {string[]} - Sorted array of category names.
 */
export const sortCategoriesAlphabetically = (categories) => {
  return categories.slice().sort((b, a) => a.localeCompare(b));
};

// Comments.js
import React, { useState, useEffect, useContext, useMemo } from 'react';
import { AuthContext } from '../context/AuthContext';
import { DisplayModeContext, TimeframeContext } from './Header';
import { Box, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { calculateDateRange } from '../utils/dateUtils';
import { capitalizeBarLabel } from '../utils/chartUtils';
import { Link } from 'react-router-dom'; // Import Link from react-router-dom

const baseUrl = process.env.REACT_APP_API_BASE_URL;

export default function Comments({ selectedCategory, selectedSubcategory }) {
  const { token } = useContext(AuthContext);
  const displayMode = useContext(DisplayModeContext);
  const timeframe = useContext(TimeframeContext);
  const [comments, setComments] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [totalReviews, setTotalReviews] = useState(0);
  const [turnover, setTurnover] = useState(null);

  useEffect(() => {
    const fetchComments = async () => {
      const businessId = localStorage.getItem('selectedBusinessId');

      if (!businessId || !token) {
        setLoading(false);
        return;
      }

      try {
        const [businessResponse, reviewsResponse, analysesResponse] = await Promise.all([
          fetch(`${baseUrl}/api/business/view/${businessId}/`, {
            method: 'GET',
            headers: {
              Authorization: `Token ${token}`,
              'Content-Type': 'application/json',
            },
          }),
          fetch(`${baseUrl}/api/reviews/business/${businessId}/`, {
            method: 'GET',
            headers: {
              Authorization: `Token ${token}`,
              'Content-Type': 'application/json',
            },
          }),
          fetch(`${baseUrl}/api/analyses/business/${businessId}/`, {
            method: 'GET',
            headers: {
              Authorization: `Token ${token}`,
              'Content-Type': 'application/json',
            },
          }),
        ]);

        if (!businessResponse.ok) throw new Error(`HTTP error! status: ${businessResponse.status}`);
        const businessData = await businessResponse.json();
        setTurnover(businessData.turnover);

        if (!reviewsResponse.ok) throw new Error(`HTTP error! status: ${reviewsResponse.status}`);
        const reviewsData = await reviewsResponse.json();
        setTotalReviews(reviewsData.length);

        if (!analysesResponse.ok) throw new Error(`HTTP error! status: ${analysesResponse.status}`);
        const analyses = await analysesResponse.json();
        if (analyses.length === 0) throw new Error('No analyses found.');
        const firstAnalysis = analyses[0];

        const commentsResponse = await fetch(`${baseUrl}/api/analyses/${firstAnalysis.id}/comments/`, {
          method: 'GET',
          headers: {
            Authorization: `Token ${token}`,
            'Content-Type': 'application/json',
          },
        });

        if (!commentsResponse.ok) throw new Error(`HTTP error! status: ${commentsResponse.status}`);

        const fetchedComments = await commentsResponse.json();
        setComments(fetchedComments);
      } catch (err) {
        console.error('Error fetching comments:', err);
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchComments();
  }, [token, displayMode, timeframe]);

  const commentsToDisplay = useMemo(() => {
    const { startDate, endDate } = calculateDateRange(timeframe);
    return comments.filter(comment => {
      const categoryMatch = selectedCategory
        ? comment.category_subcategory_mapping?.category?.name === selectedCategory
        : true;
      const subcategoryMatch = selectedSubcategory
        ? comment.category_subcategory_mapping?.subcategory?.name === selectedSubcategory
        : true;
      const reviewDate = new Date(comment.review.review_datetime);
      const timeframeMatch = reviewDate >= startDate && reviewDate <= endDate;
      return categoryMatch && subcategoryMatch && timeframeMatch;
    });
  }, [comments, selectedCategory, selectedSubcategory, timeframe]);

  const groupedComments = useMemo(() => {
    if (selectedCategory || selectedSubcategory) {
      // Group by subcategory when a category or subcategory is selected
      return commentsToDisplay.reduce((acc, item) => {
        const subcategory = item.category_subcategory_mapping?.subcategory?.name || 'Unsubcategorized';

        if (!acc[subcategory]) {
          acc[subcategory] = { comments: [], totalStars: 0 };
        }

        let impact = (item.stars_contribution || 0) / totalReviews;
        if (displayMode === 'sales' && turnover && typeof turnover === 'number') {
          impact = impact * 0.1 * turnover;
        }

        acc[subcategory].comments.push({
          comment: item.comment,
          impact: impact,
          reviewId: item.review?.id,
        });

        acc[subcategory].totalStars += Math.abs(impact);

        return acc;
      }, {});
    } else {
      // Group by category and subcategory when no category or subcategory is selected
      return commentsToDisplay.reduce((acc, item) => {
        const category = item.category_subcategory_mapping?.category?.name || 'Uncategorized';
        const subcategory = item.category_subcategory_mapping?.subcategory?.name || 'Unsubcategorized';

        if (!acc[category]) {
          acc[category] = { subcategories: {}, totalStars: 0 };
        }

        if (!acc[category].subcategories[subcategory]) {
          acc[category].subcategories[subcategory] = { comments: [], totalStars: 0 };
        }

        let impact = (item.stars_contribution || 0) / totalReviews;
        if (displayMode === 'sales' && turnover && typeof turnover === 'number') {
          impact = impact * 0.1 * turnover;
        }

        acc[category].subcategories[subcategory].comments.push({
          comment: item.comment,
          impact: impact,
          reviewId: item.review?.id,
        });

        acc[category].subcategories[subcategory].totalStars += Math.abs(impact);
        acc[category].totalStars += Math.abs(impact);

        return acc;
      }, {});
    }
  }, [commentsToDisplay, selectedCategory, selectedSubcategory, totalReviews, displayMode, turnover]);

  const totalImpact = useMemo(() => {
    return commentsToDisplay.reduce((sum, item) => {
      let impact = (item.stars_contribution || 0) / totalReviews;
      if (displayMode === 'sales' && turnover && typeof turnover === 'number') {
        impact = impact * 0.1 * turnover;
      }
      return sum + Math.abs(impact);
    }, 0);
  }, [commentsToDisplay, totalReviews, displayMode, turnover]);

  if (loading) {
    return (
      <Box textAlign="left">
        <Typography variant="h5">
          Comments
        </Typography>
        <Typography>Loading...</Typography>
      </Box>
    );
  }

  if (error) {
    return (
      <Typography align="center" color="error">
        Error: {error}
      </Typography>
    );
  }

  return (
    <Box>
      <Typography variant="h5">
        {selectedCategory || selectedSubcategory
          ? `Comments${selectedCategory ? ` - ${capitalizeBarLabel(selectedCategory)}` : ''}${selectedSubcategory ? ` - ${capitalizeBarLabel(selectedSubcategory)}` : ''}`
          : 'Comments'}
      </Typography>
      <div
        style={{
          height: '400px',
          overflowY: 'auto',        // Show scrollbar only when needed
          border: '1px solid #ccc',
          padding: '10px',
          marginRight: '15px',      // Reserve space for scrollbar
        }}
      >
        {commentsToDisplay.length > 0 ? (
          selectedCategory || selectedSubcategory ? (
            // Render comments grouped by subcategory without group names if a subcategory is selected
            Object.entries(groupedComments).map(([subcategory, subcategoryData], index) => (
              <div key={index} style={{ margin: '10px 0' }}>
                {/* Only display subcategory group name if no subcategory is selected */}
                {!selectedSubcategory && (
                  <h4 style={{ margin: '5px 0', marginLeft: '10px' }}>
                    {capitalizeBarLabel(subcategory)}{' '}
                    <strong>
                      (
                      {displayMode === 'sales'
                        ? `£${Math.round(subcategoryData.totalStars).toLocaleString('en-GB')}`
                        : subcategoryData.totalStars.toFixed(4)}
                      )
                    </strong>
                  </h4>
                )}
                {subcategoryData.comments.map((item, commentIndex) => (
                  <p key={commentIndex} style={{ margin: '5px 0', marginLeft: '20px', fontSize: '1rem' }}>
                    {item.reviewId ? (
                      <Link
                        to={`/Reviews?reviewId=${item.reviewId}`} // Use Link for client-side navigation
                        style={{
                          textDecoration: 'none', // Remove underline by default
                          color: '#1a0dab',        // Google Search link color
                          cursor: 'pointer',
                        }}
                        onMouseEnter={(e) => {
                          e.target.style.textDecoration = 'underline'; // Show underline on hover
                          e.target.style.color = 'darkblue';           // Change color on hover
                        }}
                        onMouseLeave={(e) => {
                          e.target.style.textDecoration = 'none';      // Remove underline when not hovering
                          e.target.style.color = '#1a0dab';           // Restore original color
                        }}
                      >
                        {item.comment}
                      </Link>
                    ) : (
                      <span>{item.comment}</span>
                    )}{' '}
                    ({displayMode === 'sales'
                      ? `£${Math.round(Math.abs(item.impact)).toLocaleString('en-GB')}`
                      : Math.abs(item.impact).toFixed(4)})
                  </p>
                ))}
              </div>
            ))
          ) : (
            // Render comments grouped by category and subcategory with group names
            Object.entries(groupedComments).map(([category, categoryData], index) => (
              <div key={index} style={{ margin: '10px 0' }}>
                <h3 style={{ margin: '5px 0' }}>
                  {capitalizeBarLabel(category)}{' '}
                  <strong>
                    (
                    {displayMode === 'sales'
                      ? `£${Math.round(categoryData.totalStars).toLocaleString('en-GB')}`
                      : categoryData.totalStars.toFixed(4)}
                    )
                  </strong>
                </h3>
                {Object.entries(categoryData.subcategories).map(([subcategory, subcategoryData], subIndex) => (
                  <div key={subIndex} style={{ marginLeft: '20px', margin: '5px 0' }}>
                    <h4 style={{ margin: '5px 0', marginLeft: '10px' }}>
                      {capitalizeBarLabel(subcategory)}{' '}
                      <strong>
                        (
                        {displayMode === 'sales'
                          ? `£${Math.round(subcategoryData.totalStars).toLocaleString('en-GB')}`
                          : subcategoryData.totalStars.toFixed(4)}
                        )
                      </strong>
                    </h4>
                    {subcategoryData.comments.map((item, commentIndex) => (
                      <p key={commentIndex} style={{ margin: '5px 0', marginLeft: '20px', fontSize: '1rem' }}>
                        {item.reviewId ? (
                          <Link
                            to={`/Reviews?reviewId=${item.reviewId}`} // Use Link for client-side navigation
                            style={{
                              textDecoration: 'none', // Remove underline by default
                              color: '#1a0dab',        // Google Search link color
                              cursor: 'pointer',
                            }}
                            onMouseEnter={(e) => {
                              e.target.style.textDecoration = 'underline'; // Show underline on hover
                              e.target.style.color = 'darkblue';           // Change color on hover
                            }}
                            onMouseLeave={(e) => {
                              e.target.style.textDecoration = 'none';      // Remove underline when not hovering
                              e.target.style.color = '#1a0dab';           // Restore original color
                            }}
                          >
                            {item.comment}
                          </Link>
                        ) : (
                          <span>{item.comment}</span>
                        )}{' '}
                        ({displayMode === 'sales'
                          ? `£${Math.round(Math.abs(item.impact)).toLocaleString('en-GB')}`
                          : Math.abs(item.impact).toFixed(4)})
                      </p>
                    ))}
                  </div>
                ))}
              </div>
            ))
          )
        ) : (
          <Typography align="center" color="textSecondary">
            No comments available for the selected filters.
          </Typography>
        )}
      </div>
    </Box>
  );
}

Comments.propTypes = {
  selectedCategory: PropTypes.string,
  selectedSubcategory: PropTypes.string,
};

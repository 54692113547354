import React, { useState, useRef, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { useJsApiLoader, StandaloneSearchBox } from '@react-google-maps/api';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import BusinessIcon from '@mui/icons-material/Business';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import Container from '@mui/material/Container';
import Alert from '@mui/material/Alert';
import CircularProgress from '@mui/material/CircularProgress';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { AuthContext } from '../context/AuthContext';

// Google Maps libraries
const libraries = ['places'];

// Styling using makeStyles
const useStyles = makeStyles((theme) => ({
  '@global': {
    '.pac-container': {
      zIndex: 2000, // Ensure it appears above the modal
    },
  },
  container: {
    maxWidth: '620px',
    margin: '0 auto',
    padding: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(1),
    },
  },
  paper: {
    marginTop: theme.spacing(4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  searchBoxContainer: {
    width: '600px',
    maxWidth: '100%',
    marginBottom: theme.spacing(3),
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  searchBox: {
    width: '100%',
  },
  tableContainer: {
    width: '600px',
    maxWidth: '100%',
    marginTop: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  table: {
    width: '100%',
  },
  turnoverInput: {
    width: '100%',
    '& input[type=text]': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
    '& input[type=text]::-webkit-outer-spin-button, & input[type=text]::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
    '& input[type=text]': {
      '-moz-appearance': 'textfield',
    },
  },
  addButton: {
    textTransform: 'none',
    width: '100%',
  },
  alert: {
    width: '600px',
    maxWidth: '100%',
    marginTop: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
}));

const AddBusiness = ({ onClose }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { token } = useContext(AuthContext);
  const [places, setPlaces] = useState([]);
  const [turnovers, setTurnovers] = useState({});
  const [loadingStates, setLoadingStates] = useState({});
  const [errorStates, setErrorStates] = useState({});
  const [globalError, setGlobalError] = useState(null);
  const [successMessages, setSuccessMessages] = useState({});

  const searchBoxRef = useRef(null);

  // Retrieve API keys and base URL from environment variables
  const googleApiKey = process.env.REACT_APP_GOOGLE_API_SECRET_KEY;
  const baseUrl = process.env.REACT_APP_API_BASE_URL;

  // Use useJsApiLoader to load the Google Maps API only once
  const { isLoaded, loadError } = useJsApiLoader({
    googleMapsApiKey: googleApiKey,
    libraries,
  });

  const onLoad = (ref) => {
    searchBoxRef.current = ref;
  };

  const formatNumber = (value) => {
    if (!value) return '';
    const parts = value.toString().split('.');
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    return parts.join('.');
  };

  const handleTurnoverChange = (placeId) => (event) => {
    let value = event.target.value.replace(/,/g, '');
    if (/^\d*\.?\d*$/.test(value)) {
      setTurnovers((prev) => ({ ...prev, [placeId]: value }));
    }
  };

  const onPlacesChanged = () => {
    if (searchBoxRef.current) {
      const placesResult = searchBoxRef.current.getPlaces();
      setPlaces(placesResult || []);
      setTurnovers({});
      setErrorStates({});
      setSuccessMessages({});
      setGlobalError(null);
    }
  };

  const handleAddBusiness = async (place) => {
    const turnover = turnovers[place.place_id];
    if (!turnover) {
      setErrorStates((prev) => ({
        ...prev,
        [place.place_id]: 'Please enter the business turnover.',
      }));
      return;
    }

    if (!token) {
      setGlobalError('User is not authenticated. Please log in.');
      return;
    }

    const formData = new FormData();
    formData.append('name', place.name || '');
    formData.append('address', place.formatted_address || '');
    formData.append('turnover', turnover);
    formData.append('placeId', place.place_id || '');

    const apiUrl = `${baseUrl}/api/business/`;

    setLoadingStates((prev) => ({ ...prev, [place.place_id]: true }));
    setErrorStates((prev) => ({ ...prev, [place.place_id]: null }));
    setSuccessMessages((prev) => ({ ...prev, [place.place_id]: null }));
    setGlobalError(null);

    try {
      const response = await fetch(apiUrl, {
        method: 'POST',
        headers: {
          Authorization: `Token ${token}`,
        },
        body: formData,
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.detail || 'Failed to add business.');
      }

      const result = await response.json();

      setSuccessMessages((prev) => ({
        ...prev,
        [place.place_id]: 'Business added successfully! Processing data...',
      }));
      setPlaces((prev) => prev.filter((p) => p.place_id !== place.place_id));
      setTurnovers((prev) => {
        const newTurnovers = { ...prev };
        delete newTurnovers[place.place_id];
        return newTurnovers;
      });

      if (onClose) {
        onClose();
      }
      window.location.reload();
    } catch (err) {
      console.error('Error:', err.message);
      setErrorStates((prev) => ({
        ...prev,
        [place.place_id]: err.message,
      }));
      setGlobalError(`Failed to add business: ${err.message}`);
    } finally {
      setLoadingStates((prev) => ({ ...prev, [place.place_id]: false }));
    }
  };

  if (loadError) {
    return <Alert severity="error">Error loading Google Maps API</Alert>;
  }

  if (!isLoaded) {
    return <CircularProgress />;
  }

  return (
    <Container component="main" className={classes.container}>
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <BusinessIcon />
        </Avatar>
        <Typography component="h1" variant="h5" gutterBottom>
          Add Your Business
        </Typography>
        <StandaloneSearchBox onLoad={onLoad} onPlacesChanged={onPlacesChanged}>
          <div className={classes.searchBoxContainer} style={{ position: 'relative', zIndex: 2000 }}>
            <TextField
              variant="outlined"
              required
              id="business-search"
              label="Search for your business"
              name="business-search"
              autoComplete="off"
              autoFocus
              className={classes.searchBox}
            />
          </div>
        </StandaloneSearchBox>
        {globalError && (
          <Alert severity="error" className={classes.alert}>
            {globalError}
          </Alert>
        )}
        {places.length > 0 && (
          <TableContainer component={Paper} className={classes.tableContainer}>
            <Table className={classes.table} aria-label="business table">
              <TableHead>
                <TableRow>
                  <TableCell><strong>Business Name</strong></TableCell>
                  <TableCell><strong>Address</strong></TableCell>
                  <TableCell><strong>Turnover (£)</strong></TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {places.map((place) => (
                  <TableRow key={place.place_id}>
                    <TableCell>{place.name || 'N/A'}</TableCell>
                    <TableCell>{place.formatted_address || 'N/A'}</TableCell>
                    <TableCell>
                      <TextField
                        variant="outlined"
                        required
                        type="text"
                        value={formatNumber(turnovers[place.place_id]) || ''}
                        onChange={handleTurnoverChange(place.place_id)}
                        className={classes.turnoverInput}
                        error={Boolean(errorStates[place.place_id])}
                        helperText={errorStates[place.place_id] || ''}
                      />
                    </TableCell>
                    <TableCell>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => handleAddBusiness(place)}
                        disabled={loadingStates[place.place_id]}
                        className={classes.addButton}
                      >
                        {loadingStates[place.place_id] ? (
                          <CircularProgress size={24} color="inherit" />
                        ) : (
                          'Add Business'
                        )}
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </div>
      <Box mt={5} />
    </Container>
  );
};

export default AddBusiness;

import React, { useEffect, useState, useContext, useMemo, useRef } from 'react';
import {
  Box,
  Typography,
  CssBaseline,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Rating,
  CircularProgress,
  List,
  ListItem,
  ListItemText,
  Chip,
  Button, // Import Button for "Show All Reviews"
} from '@mui/material';
import { AuthContext } from '../context/AuthContext';
import { TimeframeContext } from '../components/Header';
import { calculateDateRange } from '../utils/dateUtils';
import { useLocation, useNavigate } from 'react-router-dom';

const baseUrl = process.env.REACT_APP_API_BASE_URL;

const Reviews = () => {
  // ... existing state and context hooks
  const navigate = useNavigate();
  const location = useLocation();

  const queryParams = useMemo(() => new URLSearchParams(location.search), [location.search]);
  const reviewId = queryParams.get('reviewId');

  const { token } = useContext(AuthContext);
  const timeframe = useContext(TimeframeContext);

  const [reviews, setReviews] = useState([]);
  const [commentsByReview, setCommentsByReview] = useState({});
  const [loading, setLoading] = useState(true);

  const selectedBusinessId = localStorage.getItem('selectedBusinessId');

  // Use refs to track previous values
  const prevTimeframeRef = useRef();
  const isInitialRender = useRef(true);

  // Remove reviewId from URL when timeframe changes after initial render
  useEffect(() => {
    if (isInitialRender.current) {
      isInitialRender.current = false;
    } else if (prevTimeframeRef.current !== timeframe && reviewId) {
      const params = new URLSearchParams(location.search);
      params.delete('reviewId');
      navigate(`${location.pathname}?${params.toString()}`, { replace: true });
    }
    prevTimeframeRef.current = timeframe;
  }, [timeframe, reviewId, location, navigate]);

  useEffect(() => {
    const fetchData = async () => {
      if (selectedBusinessId && token) {
        try {
          // Fetch Reviews
          const reviewsUrl = `${baseUrl}/api/reviews/business/${selectedBusinessId}/`;
          const reviewsResponse = await fetch(reviewsUrl, {
            method: 'GET',
            headers: {
              Authorization: `Token ${token}`,
              'Content-Type': 'application/json',
            },
          });

          if (!reviewsResponse.ok) {
            throw new Error(`HTTP error! status: ${reviewsResponse.status}`);
          }

          const reviewsData = await reviewsResponse.json();
          setReviews(reviewsData);

          // Fetch Analyses
          const analysesUrl = `${baseUrl}/api/analyses/business/${selectedBusinessId}/`;
          const analysesResponse = await fetch(analysesUrl, {
            method: 'GET',
            headers: {
              Authorization: `Token ${token}`,
              'Content-Type': 'application/json',
            },
          });

          if (!analysesResponse.ok) {
            throw new Error(`HTTP error! status: ${analysesResponse.status}`);
          }

          const analysesData = await analysesResponse.json();

          if (analysesData.length > 0) {
            const currentAnalysisId = analysesData[0].id;

            // Fetch Comments using the Analysis ID
            const commentsUrl = `${baseUrl}/api/analyses/${currentAnalysisId}/comments/`;
            const commentsResponse = await fetch(commentsUrl, {
              method: 'GET',
              headers: {
                Authorization: `Token ${token}`,
                'Content-Type': 'application/json',
              },
            });

            if (!commentsResponse.ok) {
              throw new Error(`HTTP error! status: ${commentsResponse.status}`);
            }

            const commentsData = await commentsResponse.json();

            // Process comments to map them by review ID
            const mapping = commentsData.reduce((acc, commentObj) => {
              const reviewId = commentObj.review.id;
              const commentText = parseComment(commentObj.comment);

              let categoryName = '';
              let subcategoryName = '';
              if (commentObj.category_subcategory_mapping) {
                if (commentObj.category_subcategory_mapping.category) {
                  categoryName = commentObj.category_subcategory_mapping.category.name;
                }
                if (commentObj.category_subcategory_mapping.subcategory) {
                  subcategoryName = commentObj.category_subcategory_mapping.subcategory.name;
                }
              }

              const commentData = { commentText, categoryName, subcategoryName };

              if (acc[reviewId]) {
                acc[reviewId].push(commentData);
              } else {
                acc[reviewId] = [commentData];
              }
              return acc;
            }, {});
            setCommentsByReview(mapping);
          } else {
            console.warn('No analyses found for this business.');
          }
        } catch (error) {
          console.error("Error fetching data:", error);
        } finally {
          setLoading(false);
        }
      } else {
        setLoading(false);
      }
    };

    fetchData();
  }, [selectedBusinessId, token]);

  const filteredReviews = useMemo(() => {
    if (reviewId) {
      // If reviewId is specified, return only that review
      return reviews.filter((review) => review.id === reviewId);
    } else {
      // Otherwise, filter by the selected timeframe
      const { startDate, endDate } = calculateDateRange(timeframe);
      return reviews.filter((review) => {
        const reviewDate = new Date(review.review_datetime);
        return reviewDate >= startDate && reviewDate <= endDate;
      });
    }
  }, [reviews, timeframe, reviewId]);

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 4 }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box sx={{ flexGrow: 1, padding: 3 }}>
      <CssBaseline />

      {/* Show "Show All Reviews" button if a specific review is displayed */}
      {reviewId && (
        <Button
          variant="contained"
          onClick={() => {
            const params = new URLSearchParams(location.search);
            params.delete('reviewId');
            navigate(`${location.pathname}?${params.toString()}`, { replace: true });
          }}
          sx={{ marginBottom: 2 }}
        >
          Show All Reviews
        </Button>
      )}

      {filteredReviews && filteredReviews.length > 0 ? (
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell sx={{ fontWeight: 'bold', width: '10%' }}>User</TableCell>
                <TableCell sx={{ fontWeight: 'bold', width: '10%' }}>Date</TableCell>
                <TableCell sx={{ fontWeight: 'bold', width: '10%' }}>Stars</TableCell>
                <TableCell sx={{ fontWeight: 'bold', width: '35%' }}>Review</TableCell>
                <TableCell sx={{ fontWeight: 'bold', width: '35%' }}>Comments</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredReviews.map((review) => (
                <TableRow key={review.id}>
                  <TableCell>{review.user}</TableCell>
                  <TableCell>{new Date(review.review_datetime).toLocaleDateString()}</TableCell>
                  <TableCell>
                    <Rating
                      name="read-only"
                      value={review.review_stars}
                      precision={0.5}
                      readOnly
                    />
                  </TableCell>
                  <TableCell>{review.review_text}</TableCell>
                  <TableCell>
                    {commentsByReview[review.id] && commentsByReview[review.id].length > 0 ? (
                      <List sx={{ padding: 0 }}>
                        {commentsByReview[review.id].map((commentData, index) => (
                          <ListItem key={index} disablePadding sx={{ padding: '4px 0' }}>
                            <ListItemText
                              primary={
                                <Typography variant="body2">
                                  "{commentData.commentText}"
                                  <Chip
                                    label={
                                      commentData.categoryName.charAt(0).toUpperCase() +
                                      commentData.categoryName.slice(1) +
                                      ' - ' +
                                      commentData.subcategoryName.charAt(0).toUpperCase() +
                                      commentData.subcategoryName.slice(1)
                                    }
                                    size="small"
                                    sx={{ marginLeft: 1 }}
                                  />
                                </Typography>
                              }
                            />
                          </ListItem>
                        ))}
                      </List>
                    ) : (
                      <Typography variant="body2" color="textSecondary"></Typography>
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <Typography align="center" color="textSecondary">
          No reviews found.
        </Typography>
      )}
    </Box>
  );
};

// Utility function to parse comment string
const parseComment = (commentStr) => {
  try {
    const parsed = JSON.parse(commentStr);
    if (Array.isArray(parsed)) {
      return parsed.join(', ');
    }
    return commentStr;
  } catch (e) {
    return commentStr;
  }
};

export default Reviews;

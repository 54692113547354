// CategoriesChart.js

import React, { useState, useEffect, useContext } from 'react';
import { BarChart } from '@mui/x-charts/BarChart';
import { Box, Typography, useTheme } from '@mui/material';
import { AuthContext } from '../context/AuthContext';
import { DisplayModeContext, TimeframeContext } from './Header';
import PropTypes from 'prop-types';
import { calculateDateRange } from '../utils/dateUtils';
import { capitalizeBarLabel } from '../utils/chartUtils';
import { CategoryColorContext } from './CategoryColorContext';
import { sortCategoriesAlphabetically } from '../utils/categoryUtils'; // Import sorting utility

const baseUrl = process.env.REACT_APP_API_BASE_URL;

export default function CategoriesChart({ onSelectCategory, onCategoryDataUpdate }) {
  const [chartData, setChartData] = useState({ categories: ['Categories'], series: [] });
  const [loading, setLoading] = useState(true);
  const [totalDroppedStars, setTotalDroppedStars] = useState(0);
  const [turnover, setTurnover] = useState(null);
  const { token } = useContext(AuthContext);
  const displayMode = useContext(DisplayModeContext);
  const timeframe = useContext(TimeframeContext);
  const selectedBusinessId = localStorage.getItem('selectedBusinessId');
  const theme = useTheme();
  const { getCategoryColor } = useContext(CategoryColorContext); // Accessing the context

  useEffect(() => {
    const fetchData = async () => {
      if (selectedBusinessId && token) {
        try {
          // Fetch business details
          const businessResponse = await fetch(`${baseUrl}/api/business/view/${selectedBusinessId}/`, {
            method: 'GET',
            headers: {
              Authorization: `Token ${token}`,
              'Content-Type': 'application/json',
            },
          });
          if (!businessResponse.ok) throw new Error(`HTTP error! status: ${businessResponse.status}`);
          const businessData = await businessResponse.json();
          const turnoverValue = businessData.turnover; // Local variable
          setTurnover(turnoverValue);

          // Fetch reviews
          const reviewsResponse = await fetch(`${baseUrl}/api/reviews/business/${selectedBusinessId}/`, {
            method: 'GET',
            headers: {
              Authorization: `Token ${token}`,
              'Content-Type': 'application/json',
            },
          });
          if (!reviewsResponse.ok) throw new Error(`HTTP error! status: ${reviewsResponse.status}`);
          const reviewsData = await reviewsResponse.json();
          const reviewsDataLength = reviewsData.length;

          // Calculate date range
          const { startDate, endDate } = calculateDateRange(timeframe);

          // Fetch analyses
          const analysesResponse = await fetch(`${baseUrl}/api/analyses/business/${selectedBusinessId}/`, {
            method: 'GET',
            headers: {
              Authorization: `Token ${token}`,
              'Content-Type': 'application/json',
            },
          });
          if (!analysesResponse.ok) throw new Error(`HTTP error! status: ${analysesResponse.status}`);
          const analyses = await analysesResponse.json();
          if (analyses.length === 0) throw new Error('No analyses found.');
          const firstAnalysis = analyses[0];

          // Fetch comments
          const commentsResponse = await fetch(`${baseUrl}/api/analyses/${firstAnalysis.id}/comments/`, {
            method: 'GET',
            headers: {
              Authorization: `Token ${token}`,
              'Content-Type': 'application/json',
            },
          });
          if (!commentsResponse.ok) throw new Error(`HTTP error! status: ${commentsResponse.status}`);
          const comments = await commentsResponse.json();
          if (!Array.isArray(comments)) throw new Error('Invalid data format.');

          // Filter comments based on timeframe
          const filteredComments = comments.filter((comment) => {
            const reviewDate = new Date(comment.review.review_datetime);
            return reviewDate >= startDate && reviewDate <= endDate;
          });

          // Aggregate stars contribution by category
          const map = {};
          let totalDroppedStarsAccum = 0;

          filteredComments.forEach((item) => {
            const category = item.category_subcategory_mapping?.category?.name;
            let contribution = Math.abs(item.stars_contribution);

            totalDroppedStarsAccum += contribution;

            if (category) {
              map[category] = (map[category] || 0) + contribution;
            }
          });

          setTotalDroppedStars(totalDroppedStarsAccum);

          // Extract and sort categories
          const sortedCategories = sortCategoriesAlphabetically(Object.keys(map));

          // Build series data based on sorted categories
          const series = sortedCategories.map((cat, index) => {
            let impact = map[cat] / reviewsDataLength;
            if (displayMode === 'sales' && turnoverValue && typeof turnoverValue === 'number') {
              impact = impact * 0.1 * turnoverValue;
            }

            // Ensure impact is absolute
            impact = Math.abs(impact);

            const color = getCategoryColor(cat); // Get consistent color from context

            return {
              id: cat,
              label: capitalizeBarLabel(cat),
              data: [impact],
              stack: 'total',
              color: color,
              style: {
                stroke: 'white',
                strokeWidth: 1,
              },
            };
          });

          setChartData({ categories: ['Categories'], series: series });

          const categoryData = series.map((s) => ({
            name: s.label,
            color: s.color,
          }));

          if (typeof onCategoryDataUpdate === 'function') {
            onCategoryDataUpdate(categoryData);
          }
        } catch (error) {
          console.error('Error:', error);
          setChartData({ categories: ['Categories'], series: [] });
        } finally {
          setLoading(false);
        }
      } else {
        setLoading(false);
      }
    };

    fetchData();
  }, [token, displayMode, timeframe, selectedBusinessId, theme.palette.mode, getCategoryColor, onCategoryDataUpdate]);

  const handleElementClick = (event) => {
    const target = event.target;
    if (target) {
      const className = target.getAttribute('class') || '';
      const match = className.match(/MuiBarElement-series-([\w-]+)/);
      if (match && match[1]) {
        const seriesId = match[1];
        const selectedSeries = chartData.series.find((s) => s.id === seriesId);
        const categoryColor = selectedSeries ? selectedSeries.color : null;
        onSelectCategory(seriesId, categoryColor);
      }
    }
  };

  if (loading) {
    return (
      <Box textAlign="left">
        <Typography variant="h5">Categories</Typography>
        <Typography>Loading...</Typography>
      </Box>
    );
  }

  return (
    <Box>
      {chartData.series.length > 0 ? (
        <Box>
          <Typography variant="h5" gutterBottom>
            {displayMode === 'sales' ? 'Categories - Sales Impact' : 'Categories - Rating Impact'}
          </Typography>

          <Box className="custom-y-padding" sx={{ height: 400, marginTop: 2, marginBottom: 2, width: '100%' }}>
            <BarChart
              xAxis={[
                {
                  scaleType: 'band',
                  label: '',
                  data: chartData.categories,
                },
              ]}
              yAxis={[
                {
                  scaleType: 'linear',
                  label: displayMode === 'sales' ? 'Revenue (£)' : 'Rating Impact',
                  valueFormatter: (value) =>
                    displayMode === 'sales'
                      ? new Intl.NumberFormat('en-GB', {
                          style: 'currency',
                          currency: 'GBP',
                          notation: 'compact',
                          compactDisplay: 'short',
                          maximumFractionDigits: 0,
                        }).format(value)
                      : `${value.toFixed(2)}`,
                },
              ]}
              series={chartData.series}
              barSize={30} // Adjusted bar size for better fit
              padding={0.2} // Adjusted padding
              margin={theme.components.MuiCharts.styleOverrides.chartMargins}
              colors={chartData.series.map((s) => s.color)} // Using colors from context
              slotProps={{
                legend: { hidden: true },
                xAxis: {
                  label: {
                    style: {
                      fontSize: '14px',
                      fontWeight: theme.typography.fontWeightMedium,
                      fill: theme.palette.text.primary,
                    },
                  },
                  tick: {
                    style: {
                      fontSize: '12px',
                      fill: theme.palette.text.secondary,
                    },
                  },
                },
                yAxis: {
                  label: {
                    style: {
                      fontSize: '14px',
                      fontWeight: theme.typography.fontWeightMedium,
                      fill: theme.palette.text.primary,
                    },
                  },
                  tick: {
                    style: {
                      fontSize: '12px',
                      fill: theme.palette.text.secondary,
                    },
                  },
                },
                barLabel: {
                  position: 'center',
                  style: {
                    fontSize: '14px',
                    fontWeight: theme.typography.fontWeightBold,
                    fill: 'white',
                  },
                },
              }}
              barLabel={({ seriesId, value }) =>
                displayMode === 'sales'
                  ? `${capitalizeBarLabel(seriesId)}: £${Math.round(value).toLocaleString('en-GB')}`
                  : `${capitalizeBarLabel(seriesId)}: ${value.toFixed(4)}`
              }
              tooltip={{
                shared: false,
                formatter: (params) => {
                  const { seriesId, value } = params;
                  return displayMode === 'sales'
                    ? `${capitalizeBarLabel(seriesId)}: £${Math.round(value).toLocaleString('en-GB')}`
                    : `${capitalizeBarLabel(seriesId)}: ${value.toFixed(4)} Stars`;
                },
              }}
              onClick={handleElementClick}
            />
          </Box>
        </Box>
      ) : (
        <Typography align="center" color="textSecondary">
          No category data available.
        </Typography>
      )}
    </Box>
  );
}

CategoriesChart.propTypes = {
  onSelectCategory: PropTypes.func.isRequired,
  onCategoryDataUpdate: PropTypes.func.isRequired,
};

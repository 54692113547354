import React from "react";
import { useNavigate } from "react-router-dom";
import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import InsightLogo from "../components/images/insight_logo_color.svg";
import { Player } from "@lottiefiles/react-lottie-player"; // Use lottie-react or @lottiefiles/react-lottie-player
import emailSent from "../components/images/email_sent.json";

export default function VerifyEmail() {
  const navigate = useNavigate();

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <img src={InsightLogo} alt="Insight Logo" style={{ height: "100px" }} />
        <Player
          autoplay
          loop
          src={emailSent}
          style={{ height: "300px", width: "300px", margin: "0 auto" }}
        />
        <Typography component="h1" variant="h5" color="#5d5dff" sx={{ mt: 0 }}>
          Verify Your Email
        </Typography>
        <Typography variant="body1" align="center" >
          Check your inbox to complete the registration process.
        </Typography>
        <Button
          variant="contained"
          color="primary"
          sx={{ mt: 3 }}
          onClick={() => navigate("/login")}
        >
          Go to Login
        </Button>
      </Box>
    </Container>
  );
}

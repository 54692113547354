// Dashboard.js
import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import OverallRatingChart from '../components/overall_rating_chart.js';
import CategoriesChart from '../components/categories_chart.js';
import SubcategoriesChart from '../components/subcategories_chart.js';
import Comments from '../components/comments.js';
import OverallRatingOverTimeChart from '../components/overall_rating_over_time_chart.js';
import { CategoryColorProvider } from '../components/CategoryColorContext';
import ProtectedPage from "../components/ProtectedPage";


export default function BasicGrid() {
  const [selectedCategory, setSelectedCategory] = useState('');
  const [selectedCategoryColor, setSelectedCategoryColor] = useState('');
  const [selectedSubcategory, setSelectedSubcategory] = useState('');

  const handleSelectCategory = (category, categoryColor) => {
    setSelectedCategory(category);
    setSelectedCategoryColor(categoryColor);
    setSelectedSubcategory(''); // Reset subcategory when category changes
  };

  const handleSelectSubcategory = (subcategory) => {
    setSelectedSubcategory(subcategory);
  };

  const handleResetFilters = () => {
    setSelectedCategory('');
    setSelectedCategoryColor('');
    setSelectedSubcategory('');
  };

  return (
    <ProtectedPage>
      <Box sx={{ flexGrow: 1, padding: 0 }}>
        <CategoryColorProvider>
          <Grid container spacing={2}>
            {/* Overall Rating Chart Card */}
            <Grid item xs={12} md={6} lg={3}>
              <Card>
                <CardContent>
                  <OverallRatingChart onResetFilters={handleResetFilters} />
                </CardContent>
              </Card>
            </Grid>

            {/* Categories Chart Card */}
            <Grid item xs={12} md={6} lg={3}>
              <Card>
                <CardContent>
                  <CategoriesChart onSelectCategory={handleSelectCategory} onCategoryDataUpdate={() => { }} />
                </CardContent>
              </Card>
            </Grid>

            {/* Subcategories Chart Card */}
            <Grid item xs={12} md={6} lg={3}>
              <Card>
                <CardContent>
                  <SubcategoriesChart
                    selectedCategory={selectedCategory}
                    categoryColor={selectedCategoryColor}
                    onSelectSubcategory={handleSelectSubcategory}
                  />
                </CardContent>
              </Card>
            </Grid>

            {/* Comments Card */}
            <Grid item xs={12} md={6} lg={3}>
              <Card>
                <CardContent>
                  <Comments
                    selectedCategory={selectedCategory}
                    selectedSubcategory={selectedSubcategory}
                  />
                </CardContent>
              </Card>
            </Grid>
          </Grid>

          {/* Overall Rating Over Time Chart */}
          <Grid container spacing={2} sx={{ marginTop: 0 }}>
            <Grid item xs={12}>
              <Card sx={{ height: '330px' }}>
                <CardContent>
                  <OverallRatingOverTimeChart />
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </CategoryColorProvider>
      </Box>
    </ProtectedPage>
  );
}

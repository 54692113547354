import React, { useContext, createContext, useState, useEffect } from "react";
import { getSubscriptionDetails } from "../api/billing";
import { AuthContext } from "../context/AuthContext";

const SubscriptionContext = createContext();

export const SubscriptionProvider = ({ children }) => {
  const [subscriptionStatus, setSubscriptionStatus] = useState(null);
  const [businessLimit, setBusinessLimit] = useState(null); // Add a state for subscription business limit
  const [isLoading, setIsLoading] = useState(true);
  const { token } = useContext(AuthContext);

  useEffect(() => {
    const fetchSubscription = async () => {
      try {
        const data = await getSubscriptionDetails(token);

        // Extract and store subscription status
        setSubscriptionStatus(data?.subscriptions?.status);

        // Extract and store subscription business limit in state and localStorage
        const businessLimit = data?.subscription_business_limit;
        setBusinessLimit(businessLimit);

        if (businessLimit !== undefined) {
          localStorage.setItem("subscriptionBusinessLimit", businessLimit);
        }
      } catch (error) {
        setSubscriptionStatus(null);
        setBusinessLimit(null);
      }
      setIsLoading(false);
    };

    fetchSubscription();
  }, [token]);

  return (
    <SubscriptionContext.Provider
      value={{ subscriptionStatus, businessLimit, setSubscriptionStatus, isLoading }}
    >
      {children}
    </SubscriptionContext.Provider>
  );
};

export default SubscriptionContext;

import { Container, Typography, Button, Box } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Player } from "@lottiefiles/react-lottie-player"; // Use lottie-react or @lottiefiles/react-lottie-player
import somethingWentWrong from "../components/images/something_went_wrong.json"; // Adjust the path as needed
import Copyright from "../components/Copyright";


const ErrorPage = () => {
  const navigate = useNavigate();

  return (
    <Container maxWidth="sm" style={{ textAlign: "center", marginTop: "10vh" }}>
      <Player
        autoplay
        loop
        src={somethingWentWrong}
        style={{ height: "300px", width: "300px", margin: "0 auto" }}
      />
      <Typography variant="h4" color="error" gutterBottom>
        Oops, something went wrong.
      </Typography>
      <Typography variant="body1" gutterBottom>
        We were unable to confirm your email. Please try again later.
      </Typography>
      <Box mt={4}>
        <Button
          variant="contained"
          color="primary"
          onClick={() => navigate("/")}
        >
          Back to the login page
        </Button>
      </Box>
      <Box mt={5}>
        <Copyright />
      </Box>
    </Container>
  );
};

export default ErrorPage;

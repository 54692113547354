// src/App.js
import './App.css';

import { SubscriptionProvider } from "./context/SubscriptionContext";
import Home from './pages/Home.js';
import Login from './pages/Login.js';
import Myaccount from './pages/Myaccount.js';
import Profile from './pages/Profile.js';
import Landing from './pages/Landing.js';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import Dashboard from './pages/Dashboard.js';
import Reviews from './pages/Reviews.js';
import Businesses from './pages/Businesses.js';
import SignUp from "./pages/SignUp.js";
import ConfirmEmail from './pages/ConfirmEmail.js';
import EmailVerification from './pages/EmailVerification.js';
import Layout from './components/Header.js';  // Import the global layout

import Success from './pages/Success.js';
import Cancel from './pages/Cancel.js';

import EmailError from './pages/EmailErrorPage.js';
import EmailConfirmed from './pages/EmailConfirmed.js';

import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import theme from './themes/theme'; // Import the custom theme

import ProtectedRoute from './components/ProtectedRoute';
import AddBusiness from './pages/AddBusiness.js';

function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <div className="App">
        <SubscriptionProvider>
          <Router>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/login" element={<Login />} />
              <Route path="/myaccount" element={<Myaccount />} />
              <Route path="/profile" element={<Profile />} />
              <Route path="/dashboard" element={<ProtectedRoute><Layout><Dashboard /></Layout></ProtectedRoute>} />
              <Route path="/businesses" element={<ProtectedRoute><Layout><Businesses /></Layout></ProtectedRoute>} />
              <Route path="/reviews" element={<ProtectedRoute><Layout><Reviews /></Layout></ProtectedRoute>} />
              <Route path="/signup" element={<SignUp />} />
              <Route path="/landing" element={<Landing />} />
              <Route path="/success" element={<Success />} />
              <Route path="/cancel" element={<Cancel />} />
              <Route path="/confirmed-email" element={<EmailConfirmed />}/>
              <Route path="/error-email" element={<EmailError />} />
              <Route path="/addbusiness" element={<ProtectedRoute><AddBusiness /></ProtectedRoute>} />
              <Route path="/email-sent" element={<EmailVerification />} />
              <Route path="/confirm-email/:token" element={<ConfirmEmail />} />
            </Routes>
          </Router>
        </SubscriptionProvider>

      </div>
    </ThemeProvider>
  );
}

export default App;

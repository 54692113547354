import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import { Container } from "@mui/material";
import { Player } from "@lottiefiles/react-lottie-player";
import loadingAnimation from "../components/images/loading.json"; // Lottie file

const baseUrl = process.env.REACT_APP_API_BASE_URL;

const ConfirmEmail = () => {
  const { token } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const confirmEmail = async () => {
      try {
        // Sending the token as 'key' in the request body
        await axios.post(`${baseUrl}/api/users/registration/verify-email/`, {
          key: token,
        });
        // If successful, wait 2 seconds and navigate to /confirmed-email
        setTimeout(() => navigate("/confirmed-email"), 2000);
      } catch (error) {
        // If there's an error, wait 2 seconds and navigate to /error-email
        setTimeout(() => navigate("/error-email"), 2000);
      }
    };

    if (token) {
      confirmEmail();
    } else {
      // No token provided, navigate to error page after 2 seconds
      setTimeout(() => navigate("/error-email"), 2000);
    }
  }, [token, navigate]);

  return (
    <Container maxWidth="sm" style={{ textAlign: "center", marginTop: "20vh" }}>
      <Player
        autoplay
        loop
        src={loadingAnimation}
        style={{ height: "300px", width: "300px", margin: "0 auto" }}
      />
    </Container>
  );
};

export default ConfirmEmail;

import React, { useEffect, useState, useContext } from 'react';
import { AuthContext } from '../context/AuthContext';
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  CssBaseline,
  Button,
  Grid,
  Radio,
} from '@mui/material';
import AddBusinessModal from '../components/AddBusinessModal';

const baseUrl = process.env.REACT_APP_API_BASE_URL;

const BusinessesList = () => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const { token } = useContext(AuthContext);
  const [selectedBusinessId, setSelectedBusinessId] = useState(null);
  const [addBusinessModalOpen, setAddBusinessModalOpen] = useState(false); // Modal state
  const [businessLimit, setBusinessLimit] = useState(null); // State to store the business limit

  useEffect(() => {
    // Retrieve stored business ID and limit from localStorage
    const storedBusinessId = localStorage.getItem('selectedBusinessId');
    const storedBusinessLimit = localStorage.getItem('subscriptionBusinessLimit');

    if (storedBusinessId) {
      setSelectedBusinessId(storedBusinessId);
    }

    if (storedBusinessLimit) {
      setBusinessLimit(Number(storedBusinessLimit)); // Parse as number
    }
  }, []);

  useEffect(() => {
    if (token) {
      fetch(`${baseUrl}/api/business/list/`, {
        method: 'GET',
        headers: {
          Authorization: `Token ${token}`,
          'Content-Type': 'application/json',
        },
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }
          return response.json();
        })
        .then((data) => setData(data))
        .catch((error) => console.error(error))
        .finally(() => setLoading(false));
    }
  }, [token]);

  useEffect(() => {
    if (selectedBusinessId !== null) {
      localStorage.setItem('selectedBusinessId', selectedBusinessId);
    }
  }, [selectedBusinessId]);

  const handleAddBusinessButtonClick = () => {
    setAddBusinessModalOpen(true); // Open the modal
  };

  if (loading) {
    return null;
  }

  return (
    <Box sx={{ flexGrow: 1, padding: 3 }}>
      <CssBaseline />
      <Box mb={2}>
        <Grid container alignItems="center">
          <Grid item xs={6}>
            <Typography variant="h4" align="left" gutterBottom>
              Businesses
            </Typography>
          </Grid>
          <Grid item xs={6} sx={{ textAlign: 'right' }}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleAddBusinessButtonClick} // Open modal
              disabled={data && businessLimit !== null && data.length >= businessLimit} // Disable if limit is reached
            >
              Add Business
            </Button>
          </Grid>
        </Grid>
      </Box>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell sx={{ fontSize: '1.25rem', fontWeight: 'bold' }}>
                Business Name
              </TableCell>
              <TableCell sx={{ fontSize: '1.25rem', fontWeight: 'bold' }}>
                Address
              </TableCell>
              <TableCell sx={{ fontSize: '1.25rem', fontWeight: 'bold' }}>
                Turnover
              </TableCell>
              <TableCell sx={{ fontSize: '1.25rem', fontWeight: 'bold' }}>
                Select
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data && data.length > 0 ? (
              data.map((business, index) => (
                <TableRow
                  key={business.id}
                  hover
                  onClick={() => setSelectedBusinessId(String(business.id))}
                  sx={{ cursor: 'pointer' }}
                >
                  <TableCell>{business.name}</TableCell>
                  <TableCell>{business.address}</TableCell>
                  <TableCell>{`£${Math.round(business.turnover).toLocaleString('en-GB')}`}</TableCell>
                  <TableCell>
                    <Radio
                      checked={selectedBusinessId === String(business.id)}
                      onChange={() => {
                        setSelectedBusinessId(String(business.id));
                        localStorage.setItem('selectedBusinessId', business.id); // Store selected ID
                        window.location.reload(); // Reload the page
                      }}
                      value={business.id}
                      name="business-radio-button"
                      inputProps={{ 'aria-label': business.name }}
                    />
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={4}>
                  <Typography align="center" color="textSecondary">
                    No businesses found.
                  </Typography>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <AddBusinessModal
        open={addBusinessModalOpen}
        onClose={() => setAddBusinessModalOpen(false)} // Close modal
      />
    </Box>
  );
};

export default BusinessesList;

import React from "react";
import { useNavigate } from "react-router-dom";
import { Box, Button, Typography, Container } from "@mui/material";
import { Player } from "@lottiefiles/react-lottie-player";
import InsightLogo from "../components/images/insight_logo_color.svg";
import somethingWentWrong from "../components/images/payment_failure.json"; // Adjust the path as needed
import Copyright from "../components/Copyright";


export default function Cancel() {
  const navigate = useNavigate();

  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          textAlign: "center",
        }}
      >
        <img src={InsightLogo} alt="Insight Logo" style={{ height: "100px" }} />
        <Player
          autoplay
          loop
          src={somethingWentWrong}
          style={{ height: "300px", width: "300px", margin: "0 auto" }}
        />
        <Typography variant="h5" component="h1" gutterBottom>
          Payment Failed or Canceled
        </Typography>
        <Typography variant="body1" color="textSecondary" sx={{ mb: 3 }}>
          We're sorry, but it seems your payment didn't go through. Please try again or contact support if you need assistance.
        </Typography>
        <Button
          variant="contained"
          color="primary"
          fullWidth
          sx={{ mt: 2 }}
          onClick={() => navigate(-1)}
        >
          Retry Payment
        </Button>
        <Button
          variant="outlined"
          color="secondary"
          fullWidth
          sx={{ mt: 2 }}
          component="a" // Use anchor tag for mailto
          href="mailto:support@dotfit.ai"
        >
          Contact Support
        </Button>
      </Box>
      <Box mt={5}>
        <Copyright />
      </Box>
    </Container>
  );
}

// CategoryColorContext.js

import React, { createContext, useRef } from 'react';
import { blueberryTwilightPalette } from '@mui/x-charts/colorPalettes';
import { useTheme } from '@mui/material/styles';
import PropTypes from 'prop-types';

export const CategoryColorContext = createContext();

export const CategoryColorProvider = ({ children }) => {
  const theme = useTheme();
  const paletteColors = blueberryTwilightPalette(theme.palette.mode);

  // Using useRef to maintain the color mapping and index without causing re-renders
  const categoryColorMappingRef = useRef({});
  const colorIndexRef = useRef(0);

  const getCategoryColor = (category) => {
    if (!categoryColorMappingRef.current[category]) {
      // Assign a new color from the palette
      const color = paletteColors[colorIndexRef.current % paletteColors.length];
      categoryColorMappingRef.current[category] = color;
      colorIndexRef.current += 1;
    }
    return categoryColorMappingRef.current[category];
  };

  return (
    <CategoryColorContext.Provider value={{ getCategoryColor }}>
      {children}
    </CategoryColorContext.Provider>
  );
};

CategoryColorProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

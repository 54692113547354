export const getSubscriptionDetails = async (token) => {
  const baseUrl = process.env.REACT_APP_API_BASE_URL;
  try {
    const response = await fetch(`${baseUrl}/api/billings/subscription-details/`, {
      method: "GET",
      headers: {
        Authorization: `Token ${token}`,
        "Content-Type": "application/json",
      },
      credentials: "include",
    });

    const rawResponse = await response.text();
    const data = JSON.parse(rawResponse);

    if (!response.ok) {
      throw new Error("Failed to fetch subscription details");
    }

    return data;
  } catch (error) {
    console.error("Error fetching subscription details:", error);
    throw error;
  }
};

// Header.js
import React, { useState, useContext, useEffect, createContext } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import {
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  Menu,
  MenuItem,
  Drawer,
  List,
  ListItemButton,
  ListItemText,
  ListItemIcon,
  CssBaseline,
  Box,
  FormControl,
  Select,
  CircularProgress,
  ToggleButton,
  ToggleButtonGroup,
} from '@mui/material';
import SettingsIcon from '@mui/icons-material/Settings';
import {
  Home as HomeIcon,
  RateReview as RateReviewIcon,
  Business as BusinessIcon,
} from '@mui/icons-material';
import { AuthContext } from '../context/AuthContext';

const baseUrl = process.env.REACT_APP_API_BASE_URL;
const drawerWidth = 200;

// Create contexts
export const DisplayModeContext = createContext();
export const TimeframeContext = createContext();

export default function Header({ children }) {
  const navigate = useNavigate();
  const location = useLocation();
  const { setToken, token } = useContext(AuthContext);

  // Settings menu state
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  // Business list and selection state
  const [businesses, setBusinesses] = useState([]);
  const [selectedBusinessId, setSelectedBusinessId] = useState(
    localStorage.getItem('selectedBusinessId') || null
  );
  const [businessesLoading, setBusinessesLoading] = useState(true);

  // Display mode state
  const [displayMode, setDisplayMode] = useState('sales');

  // Timeframe state
  const [timeframe, setTimeframe] = useState('Last 90 Days');

  // Fetch businesses on mount
  useEffect(() => {
    const fetchBusinesses = async () => {
      if (token) {
        try {
          const response = await fetch(`${baseUrl}/api/business/list/`, {
            method: 'GET',
            headers: {
              Authorization: `Token ${token}`,
              'Content-Type': 'application/json',
            },
          });
          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }
          const data = await response.json();
          setBusinesses(data);

          if (data.length === 0) {
            // Redirect to AddBusiness page if no businesses are available
            navigate('/businesses');
          } else {
            // Set selected business ID if not already set
            if (!selectedBusinessId) {
              const firstBusinessId = String(data[0].id);
              setSelectedBusinessId(firstBusinessId);
              localStorage.setItem('selectedBusinessId', firstBusinessId);
            }
          }
        } catch (error) {
          console.error('Error fetching businesses:', error);
        } finally {
          setBusinessesLoading(false);
        }
      }
    };

    fetchBusinesses();
  }, [token, navigate, selectedBusinessId]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  // Logout function
  const handleLogout = () => {
    localStorage.removeItem('authToken');
    setToken('');
    handleClose();
    navigate('/login');
  };

  // Handle display mode change
  const handleDisplayModeChange = (event, newMode) => {
    if (newMode !== null) {
      setDisplayMode(newMode);
    }
  };

  // Handle timeframe change
  const handleTimeframeChange = (event) => {
    setTimeframe(event.target.value);
  };

  // Define the menu items
  const menuItems = [
    {
      text: 'Performance',
      icon: <HomeIcon />,
      path: '/dashboard',
    },
    {
      text: 'Reviews',
      icon: <RateReviewIcon />,
      path: '/reviews',
    },
    {
      text: 'Businesses',
      icon: <BusinessIcon />,
      path: '/businesses',
    },
  ];

  return (
    <DisplayModeContext.Provider value={displayMode}>
      <TimeframeContext.Provider value={timeframe}>
        <Box sx={{ display: 'flex' }}>
          <CssBaseline />

          {/* AppBar for the top bar */}
          <AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
            <Toolbar>
              <Typography variant="h6" noWrap sx={{ flexGrow: 1, fontWeight: 'bold' }}>
                Insight
              </Typography>

              {/* Business Selector */}
              {businessesLoading ? (
                <CircularProgress color="inherit" size={24} />
              ) : (
                businesses.length > 0 && (
                  <FormControl
                    variant="outlined"
                    size="small"
                    sx={{ minWidth: 200, marginRight: 2 }}
                  >
                    <Select
                      labelId="business-select-label"
                      id="business-select"
                      value={selectedBusinessId || ''}
                      sx={{
                        width: 200,
                        marginRight: 2,
                        border: '1px solid #1976d2',
                        borderRadius: '8px',
                        backgroundColor: '#ffffff',
                        boxShadow: '0px 4px 6px rgba(0,0,0,0.1)',
                      }}
                      onChange={(e) => {
                        const businessId = e.target.value;
                        setSelectedBusinessId(businessId);
                        localStorage.setItem('selectedBusinessId', businessId);
                        window.location.reload();
                      }}
                    >
                      {businesses.map((business) => (
                        <MenuItem key={business.id} value={String(business.id)}>
                          {business.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )
              )}

              {/* Toggle Button for Display Mode */}
              <ToggleButtonGroup
                value={displayMode}
                exclusive
                onChange={handleDisplayModeChange}
                aria-label="display mode"
                size="small"
                sx={{
                  width: 150,
                  marginRight: 2,
                  border: '1px solid #1976d2',
                  borderRadius: '8px',
                  backgroundColor: '#ffffff',
                  boxShadow: '0px 4px 6px rgba(0,0,0,0.1)',
                }}
              >
                <ToggleButton
                  value="sales"
                  aria-label="sales impact"
                  sx={{
                    flex: 1,
                    color: displayMode === 'sales' ? '#1976d2' : '#000',
                    backgroundColor: displayMode === 'sales' ? '#e3f2fd' : '#fff',
                    '&:hover': {
                      backgroundColor: '#bbdefb',
                    },
                  }}
                >
                  Sales
                </ToggleButton>
                <ToggleButton
                  value="rating"
                  aria-label="rating impact"
                  sx={{
                    flex: 1,
                    color: displayMode === 'rating' ? '#1976d2' : '#000',
                    backgroundColor: displayMode === 'rating' ? '#e3f2fd' : '#fff',
                    '&:hover': {
                      backgroundColor: '#bbdefb',
                    },
                  }}
                >
                  Rating
                </ToggleButton>
              </ToggleButtonGroup>

              {/* Timeframe Selector */}
              <FormControl variant="outlined" size="small" sx={{ minWidth: 150, marginRight: 2 }}>
                <Select
                  id="timeframe-select"
                  value={timeframe}
                  onChange={handleTimeframeChange}
                  displayEmpty
                  inputProps={{ 'aria-label': 'Timeframe' }}
                  sx={{
                        width: 200,
                        marginRight: 2,
                        border: '1px solid #1976d2',
                        borderRadius: '8px',
                        backgroundColor: '#ffffff',
                        boxShadow: '0px 4px 6px rgba(0,0,0,0.1)',
                      }}
                >
                  <MenuItem value="Last 7 Days">Last 7 Days</MenuItem>
                  <MenuItem value="Last 30 Days">Last 30 Days</MenuItem>
                  <MenuItem value="Last 90 Days">Last 90 Days</MenuItem>
                  <MenuItem value="Last 365 Days">Last 365 Days</MenuItem>
                  <MenuItem value="All Time">All Time</MenuItem>
                </Select>
              </FormControl>

              {/* Settings Icon */}
              <IconButton
                size="large"
                edge="end"
                color="inherit"
                aria-label="settings"
                aria-controls="settings-menu"
                aria-haspopup="true"
                onClick={handleClick}
              >
                <SettingsIcon />
              </IconButton>
              <Menu
                id="settings-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                  'aria-labelledby': 'settings-button',
                }}
              >
                <MenuItem onClick={handleLogout}>Logout</MenuItem>
              </Menu>
            </Toolbar>
          </AppBar>

          {/* Sidebar */}
          <Drawer
            sx={{
              width: drawerWidth,
              flexShrink: 0,
              '& .MuiDrawer-paper': {
                width: drawerWidth,
                boxSizing: 'border-box',
              },
            }}
            variant="permanent"
            anchor="left"
          >
            <Toolbar />
            <List>
              {menuItems.map((item) => (
                <ListItemButton
                  key={item.text}
                  selected={location.pathname === item.path}
                  onClick={() => navigate(item.path)}
                >
                  <ListItemIcon>{item.icon}</ListItemIcon>
                  <ListItemText primary={item.text} />
                </ListItemButton>
              ))}
            </List>
          </Drawer>

          {/* Main content */}
          <Box
            component="main"
            sx={{
              flexGrow: 1,
              p: 3,
              marginTop: '64px',
            }}
          >
            {children}
          </Box>
        </Box>
      </TimeframeContext.Provider>
    </DisplayModeContext.Provider>
  );
}

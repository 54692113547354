// OverallRatingOverTimeChart.js

import React, { useEffect, useState, useContext, useMemo } from 'react';
import { LineChart } from '@mui/x-charts/LineChart';
import { AuthContext } from '../context/AuthContext';
import { TimeframeContext, DisplayModeContext } from './Header';
import { calculateDateRange } from '../utils/dateUtils';
import { Box, Typography, useTheme } from '@mui/material';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';

// Import CategoryColorContext and sorting utility
import { CategoryColorContext } from './CategoryColorContext';
import { sortCategoriesAlphabetically } from '../utils/categoryUtils';

const baseUrl = process.env.REACT_APP_API_BASE_URL;

export default function OverallRatingOverTimeChart() {
  const [reviews, setReviews] = useState([]);
  const [salesSeriesData, setSalesSeriesData] = useState([]);
  const [dates, setDates] = useState([]);
  const [turnover, setTurnover] = useState(null); // Added turnover state
  const selectedBusinessId = localStorage.getItem('selectedBusinessId');
  const { token } = useContext(AuthContext);
  const timeframe = useContext(TimeframeContext);
  const displayMode = useContext(DisplayModeContext);
  const theme = useTheme();
  const { getCategoryColor } = useContext(CategoryColorContext); // Access CategoryColorContext

  // Define a currency formatter for GBP
  const currencyFormatterGBP = useMemo(
    () =>
      new Intl.NumberFormat('en-GB', {
        style: 'currency',
        currency: 'GBP',
        maximumFractionDigits: 0,
      }).format,
    []
  );

  useEffect(() => {
    if (displayMode === 'sales') {
      // Fetch and process data for sales mode
      const fetchData = async () => {
        try {
          // Fetch business details
          const businessResponse = await fetch(`${baseUrl}/api/business/view/${selectedBusinessId}/`, {
            method: 'GET',
            headers: {
              Authorization: `Token ${token}`,
              'Content-Type': 'application/json',
            },
          });
          if (!businessResponse.ok) throw new Error(`HTTP error! status: ${businessResponse.status}`);
          const businessData = await businessResponse.json();
          const turnoverValue = businessData.turnover;
          setTurnover(turnoverValue); // Update turnover state

          // Fetch reviews
          const reviewsResponse = await fetch(`${baseUrl}/api/reviews/business/${selectedBusinessId}/`, {
            method: 'GET',
            headers: {
              Authorization: `Token ${token}`,
              'Content-Type': 'application/json',
            },
          });
          if (!reviewsResponse.ok) throw new Error(`HTTP error! status: ${reviewsResponse.status}`);
          const reviewsData = await reviewsResponse.json();

          // Fetch analyses
          const analysesResponse = await fetch(`${baseUrl}/api/analyses/business/${selectedBusinessId}/`, {
            method: 'GET',
            headers: {
              Authorization: `Token ${token}`,
              'Content-Type': 'application/json',
            },
          });
          if (!analysesResponse.ok) throw new Error(`HTTP error! status: ${analysesResponse.status}`);
          const analyses = await analysesResponse.json();
          if (analyses.length === 0) throw new Error('No analyses found.');
          const firstAnalysis = analyses[0];

          // Fetch comments
          const commentsResponse = await fetch(`${baseUrl}/api/analyses/${firstAnalysis.id}/comments/`, {
            method: 'GET',
            headers: {
              Authorization: `Token ${token}`,
              'Content-Type': 'application/json',
            },
          });
          if (!commentsResponse.ok) throw new Error(`HTTP error! status: ${commentsResponse.status}`);
          const comments = await commentsResponse.json();
          if (!Array.isArray(comments)) throw new Error('Invalid data format.');

          // Get the earliest date from comments
          const commentDates = comments.map((comment) => new Date(comment.review.review_datetime));
          const earliestCommentDate = new Date(Math.min(...commentDates));

          // Calculate date range
          const { startDate: calculatedStartDate, endDate } = calculateDateRange(timeframe);

          // If timeframe is 'All Time', adjust startDate to earliest comment date
          let startDate = calculatedStartDate;
          if (timeframe === 'All Time') {
            startDate = earliestCommentDate;
          }

          // Filter comments based on timeframe
          const filteredComments = comments.filter((comment) => {
            const reviewDate = new Date(comment.review.review_datetime);
            return reviewDate >= startDate && reviewDate <= endDate;
          });

          // Create 1-month rolling intervals
          function getOneMonthIntervals(startDate, endDate) {
            const intervals = [];
            let currentStart = new Date(startDate);
            currentStart.setDate(1); // Set to first day of the month
            while (currentStart <= endDate) {
              let currentEnd = new Date(currentStart);
              currentEnd.setMonth(currentEnd.getMonth() + 1);
              currentEnd.setDate(0); // Set to last day of the previous month
              if (currentEnd > endDate) {
                currentEnd = new Date(endDate);
              }
              intervals.push({
                start: new Date(currentStart),
                end: new Date(currentEnd),
              });
              currentStart.setMonth(currentStart.getMonth() + 1); // Move forward by 1 month
            }
            return intervals;
          }

          const intervals = getOneMonthIntervals(startDate, endDate);

          // For each interval, aggregate category values
          const intervalData = intervals.map((interval) => {
            // Filter comments within the interval
            const intervalComments = filteredComments.filter((comment) => {
              const reviewDate = new Date(comment.review.review_datetime);
              return reviewDate >= interval.start && reviewDate <= interval.end;
            });

            // Aggregate stars contribution by category
            const map = {};
            intervalComments.forEach((item) => {
              const category = item.category_subcategory_mapping?.category?.name;
              let contribution = Math.abs(item.stars_contribution);
              if (category) {
                map[category] = (map[category] || 0) + contribution;
              }
            });

            // Calculate impact per category
            const categoriesImpact = Object.keys(map).map((cat) => {
              let impact = map[cat] / reviewsData.length;
              if (turnoverValue && typeof turnoverValue === 'number') {
                impact = impact * 0.1 * turnoverValue;
              }
              return {
                name: cat, // Changed from 'category' to 'name' for consistency
                impact: impact,
              };
            });

            return {
              intervalStart: interval.start,
              categoriesImpact: categoriesImpact,
            };
          });

          // Extract all categories
          const allCategoriesSet = new Set();
          intervalData.forEach((interval) => {
            interval.categoriesImpact.forEach((item) => {
              allCategoriesSet.add(item.name); // Changed from 'item.category' to 'item.name'
            });
          });
          const allCategories = Array.from(allCategoriesSet);

          // Sort categories alphabetically
          const sortedCategories = sortCategoriesAlphabetically(allCategories);

          // Build series data based on sorted categories
          const dates = intervalData.map((interval) => interval.intervalStart);

          // Prepare dataset for stacked line chart with zeros for missing values
          const dataset = dates.map((date, idx) => {
            const dataPoint = { date };
            sortedCategories.forEach((category) => {
              const impactItem = intervalData[idx].categoriesImpact.find((item) => item.name === category);
              dataPoint[category] = impactItem ? impactItem.impact : 0;
            });
            return dataPoint;
          });

          // Build series configuration using CategoryColorContext
          const stackStrategy = {
            stack: 'total',
            area: true,
            stackOffset: 'none',
          };

          const series = sortedCategories.map((category) => ({
            dataKey: category,
            label: category.charAt(0).toUpperCase() + category.slice(1),
            color: getCategoryColor(category), // Get color from context
            showMark: false,
            markerSize: 0,
            valueFormatter: currencyFormatterGBP, // Added valueFormatter for currency
            ...stackStrategy,
          }));

          setDates(dates);
          setSalesSeriesData({ dataset, series });
        } catch (error) {
          console.error('Error fetching sales data:', error);
        }
      };

      fetchData(); // Ensure this call is inside the 'if' block and properly closed
    } else {
      // Existing logic for ratings mode
      const fetchReviews = async () => {
        try {
          const response = await fetch(
            `${baseUrl}/api/reviews/business/${selectedBusinessId}/`,
            {
              headers: {
                Authorization: `Token ${token}`,
              },
            }
          );
          if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);
          const data = await response.json();
          setReviews(data);
        } catch (error) {
          console.error('Error fetching reviews:', error);
        }
      };

      fetchReviews();
    }
  }, [selectedBusinessId, token, displayMode, timeframe, theme.palette.mode, getCategoryColor, turnover, currencyFormatterGBP]);

  // Existing logic for ratings mode
  // Sort reviews in chronological order
  const sortedReviews = useMemo(() => {
    return [...reviews].sort(
      (a, b) => new Date(a.review_datetime) - new Date(b.review_datetime)
    );
  }, [reviews]);

  // Calculate cumulative average ratings over all reviews
  const cumulativeRatings = useMemo(() => {
    const ratings = [];
    let sum = 0;
    sortedReviews.forEach((review, index) => {
      sum += review.review_stars;
      ratings.push(sum / (index + 1));
    });
    return ratings;
  }, [sortedReviews]);

  // Get dates from sorted reviews
  const ratingDates = useMemo(
    () => sortedReviews.map((review) => new Date(review.review_datetime)),
    [sortedReviews]
  );

  // Calculate the date range based on the selected timeframe
  const { startDate: calculatedStartDate, endDate } = useMemo(
    () => calculateDateRange(timeframe),
    [timeframe]
  );

  // Adjust startDate if timeframe is 'All Time'
  let startDate = calculatedStartDate;
  if (timeframe === 'All Time' && ratingDates.length > 0) {
    startDate = ratingDates[0];
  }

  // Filter dates and cumulative ratings based on the timeframe
  const filteredRatingData = useMemo(() => {
    const filteredDates = [];
    const filteredRatings = [];
    ratingDates.forEach((date, index) => {
      if (date >= startDate && date <= endDate) {
        filteredDates.push(date);
        filteredRatings.push(cumulativeRatings[index]);
      }
    });
    return { filteredDates, filteredRatings };
  }, [ratingDates, cumulativeRatings, startDate, endDate]);

  // Calculate the delta in overall rating for the selected timeframe
  const deltaRating = useMemo(() => {
    const filteredRatings = filteredRatingData.filteredRatings;
    if (filteredRatings.length > 1) {
      return filteredRatings[filteredRatings.length - 1] - filteredRatings[0];
    }
    return 0; // No change if there is only one or no rating
  }, [filteredRatingData.filteredRatings]);

  return (
    <Box height="100%"> {/* Ensure the component fills the parent height */}
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
        <Typography variant="h5">
          {displayMode === 'sales' ? 'Category Sales Impact Over Time' : 'Overall Rating Over Time'}
        </Typography>
        {displayMode !== 'sales' && (
          <Box display="flex" alignItems="center" sx={{ textAlign: 'right' }}>
            <Typography
              variant="subtitle1"
              sx={{ fontWeight: 'bold', fontSize: '1.25rem', marginRight: '8px' }}
            >
              {deltaRating.toFixed(3)}
            </Typography>
            {deltaRating > 0 ? (
              <ArrowUpwardIcon />
            ) : deltaRating < 0 ? (
              <ArrowDownwardIcon />
            ) : null}
          </Box>
        )}
      </Box>
      <Box
        className="custom-y-padding"
        sx={{ height: 'calc(100% - 60px)', display: 'flex', flexDirection: 'row', alignItems: 'stretch' }} // Adjusted height
      >
        {displayMode === 'sales' ? (
          salesSeriesData.series && salesSeriesData.series.length > 0 ? (
            <>
              <Box sx={{ flex: 1, height: '100%' }}>
                <LineChart
                  xAxis={[
                    {
                      dataKey: 'date',
                      scaleType: 'time',
                      labelFormatter: (date) => {
                        const options = {
                          year: 'numeric',
                          month: 'short',
                        };
                        return new Date(date).toLocaleString(undefined, options);
                      },
                    },
                  ]}
                  yAxis={[
                    {
                      label: 'Sales Impact (£)',
                      valueFormatter: (value) =>
                        new Intl.NumberFormat('en-GB', {
                          style: 'currency',
                          currency: 'GBP',
                          notation: 'compact',
                          compactDisplay: 'short',
                          maximumFractionDigits: 0,
                        }).format(value),
                    },
                  ]}
                  series={salesSeriesData.series}
                  dataset={salesSeriesData.dataset}
                  grid={{ horizontal: true }}
                  margin={{ top: 20, right: 30, bottom: 40, left: 80 }}
                  // Removed fixed height
                  legend={{
                    layout: 'vertical',
                    align: 'right',
                    verticalAlign: 'middle',
                    hidden: true,
                  }}
                  slotProps={{
                    xAxis: {
                      label: {
                        style: {
                          fontSize: '12px',
                          fill: theme.palette.text.primary,
                        },
                      },
                      tick: {
                        style: {
                          fontSize: '10px',
                          fill: theme.palette.text.secondary,
                        },
                      },
                    },
                    yAxis: {
                      label: {
                        style: {
                          fontSize: '12px',
                          fill: theme.palette.text.primary,
                        },
                      },
                      tick: {
                        style: {
                          fontSize: '10px',
                          fill: theme.palette.text.secondary,
                        },
                      },
                    },
                    legend: {
                      style: {
                        fontSize: '10px',
                        fill: theme.palette.text.secondary,
                        hidden: true,
                      },
                    },
                  }}
                  style={{ height: '100%' }} // Ensure chart fills the container
                />
              </Box>
              <Box
                sx={{
                  width: 150,
                  marginLeft: 2,
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  height: '100%', // Ensure the legend box takes full height
                }}
              >
                {/* Custom Legend */}
                {/* Reverse the series array for the legend */}
                {salesSeriesData.series.slice().reverse().map((serie) => (
                  <Box key={serie.dataKey} display="flex" alignItems="center" mb={1}>
                    <Box
                      sx={{
                        width: 16,
                        height: 16,
                        backgroundColor: serie.color,
                        marginRight: 1,
                      }}
                    />
                    <Typography variant="body2">{serie.label}</Typography>
                  </Box>
                ))}
              </Box>
            </>
          ) : (
            <Typography align="center" color="textSecondary">
              No data to display.
            </Typography>
          )
        ) : filteredRatingData.filteredRatings.length > 0 ? (
          <LineChart
            xAxis={[
              {
                data: filteredRatingData.filteredDates,
                scaleType: 'time',
                labelFormatter: (date) => {
                  const options = {
                    year: 'numeric',
                    month: 'short',
                    day: 'numeric',
                  };
                  return new Date(date).toLocaleString(undefined, options);
                },
              },
            ]}
            yAxis={[
              {
                label: 'Rating',
              },
            ]}
            series={[
              {
                data: filteredRatingData.filteredRatings,
                name: 'Cumulative Rating',
                color: '#4682B4', // Steel Blue
                markerSize: 0,
                showMark: false,
                fillOpacity: 0.3,
              },
            ]}
            grid={{ horizontal: true }}
            margin={{ top: 20, right: 30, bottom: 40, left: 80 }}
            // Removed fixed height
            slotProps={{
              xAxis: {
                label: {
                  style: {
                    fontSize: '12px',
                    fill: theme.palette.text.primary,
                  },
                },
                tick: {
                  style: {
                    fontSize: '10px',
                    fill: theme.palette.text.secondary,
                  },
                },
              },
              yAxis: {
                label: {
                  style: {
                    fontSize: '12px',
                    fill: theme.palette.text.primary,
                  },
                },
                tick: {
                  style: {
                    fontSize: '10px',
                    fill: theme.palette.text.secondary,
                  },
                },
              },
            }}
            style={{ width: '100%', height: '100%' }} // Ensure chart fills the container
          />
        ) : (
          <Typography align="center" color="textSecondary">
            No data to display.
          </Typography>
        )}
      </Box>
    </Box>
  );
}

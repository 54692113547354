import React, { useState } from 'react';
import { makeStyles } from '@mui/styles';
import {
  Grid,
  TextField,
  Button,
  Typography,
  CssBaseline,
  Box,
  Link,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import landingImage from '../components/images/landing_img.png';
import theme from '../themes/theme'; // Import the custom theme

const baseUrl = process.env.REACT_APP_API_BASE_URL;

const useStyles = makeStyles((theme) => ({
  root: {
    // Centers the content vertically and horizontally
    height: '100vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative', // Required for the pseudo-element positioning
    backgroundImage: `url(${landingImage})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    // Overlay styles
    '&::before': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      backgroundColor: 'rgba(255, 255, 255, 0.6)', // Semi-transparent overlay
      backdropFilter: 'blur(8px)', // Blur effect
      zIndex: 1, // Ensures overlay is above the background image
    },
  },
  content: {
    position: 'relative',
    zIndex: 2, // Ensures content is above the overlay
    backgroundColor: 'rgba(255, 255, 255)', // Slightly transparent background
    padding: theme.spacing(4),
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[5],
    maxWidth: 460,
    width: '100%',
    margin: '0 auto',
    overflow: 'hidden', // Prevents negative margins from affecting layout
    boxSizing: 'border-box',
  },
  form: {
    width: '100%',
    marginTop: theme.spacing(3),
    '& .MuiTextField-root': {
      marginBottom: theme.spacing(2),
    },
  },
  submit: {
    marginTop: theme.spacing(2),
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  header: {
    position: 'absolute',
    top: theme.spacing(2),
    left: theme.spacing(2),
    fontWeight: 'bold',
    fontSize: '1.5rem',
    color: theme.palette.primary.main,
  },
  description: {
    marginTop: theme.spacing(1),
    color: theme.palette.text.secondary,
  },
}));

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://dotfit.ai/">
        DotFit Ltd
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

export default function Landing() {
  const classes = useStyles();
  const navigate = useNavigate();
  const [error, setError] = useState('');

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const email = data.get('email');
    const password1 = data.get('password1');
    const password2 = data.get('password2');

    // Make sure passwords match
    if (password1 !== password2) {
      setError('Passwords do not match');
      return;
    }

    fetch(`${baseUrl}/api/users/registration/`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        email: email,
        password1: password1,
        password2: password2,
      }),
    })
      .then((response) => {
        if (response.status === 204) {
          navigate('/login');
        } else if (response.ok) {
          return response.json();
        } else {
          return response.json().then((data) => {
            throw new Error(data.error || 'Registration failed');
          });
        }
      })
      .then((data) => {
        console.log('Registration successful:', data);
      })
      .catch((error) => {
        console.error('Error during registration:', error);
        setError(error.message || 'An error occurred during registration.');
      });
  };

  return (
    <div className={classes.root}>
      <CssBaseline />
      <div className={classes.content}>
        <Typography variant="h5" align="center">
          Sign up
        </Typography>
        <Typography className={classes.description} align="center">
          See analysis of your Google reviews to improve your rating.
        </Typography>
        {error && (
          <Typography color="error" align="center">
            {error}
          </Typography>
        )}
        <form className={classes.form} onSubmit={handleSubmit} noValidate>
          {/* Wrapping Grid in a div with overflow: hidden to prevent layout issues */}
          <div style={{ overflow: 'hidden' }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  id="email"
                  name="email"
                  autoComplete="email"
                  autoFocus
                  placeholder="Enter your email address"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  name="password1"
                  label="Password"
                  type="password"
                  id="password1"
                  autoComplete="new-password"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  name="password2"
                  label="Confirm Password"
                  type="password"
                  id="password2"
                  autoComplete="new-password"
                />
              </Grid>
            </Grid>
          </div>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            Sign Up
          </Button>
          <Box mt={2} textAlign="center">
            <Typography variant="body2">
              Already have an account?{' '}
              <Link href="#" variant="body2" onClick={() => navigate('/login')}>
                Sign in
              </Link>
            </Typography>
          </Box>
        </form>
      </div>
      <Box mt={5} position="absolute" bottom={theme.spacing(2)}>
        <Typography variant="body2" color="textSecondary" align="center">
          {'Copyright © '}
          <Link color="inherit" href="https://dotfit.ai/">
            DotFit Ltd
          </Link>{' '}
          {new Date().getFullYear()}
          {'.'}
        </Typography>
      </Box>
    </div>
  );
}
